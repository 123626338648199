
import {Component, Vue} from 'vue-property-decorator';
import PageHeader from "@/components/page/PageHeader.vue";
// import {labels} from "./../mock/labels"
import PageCard from "@/components/page/PageCard.vue";

@Component({
  components: {
    PageCard,
    PageHeader
  }
})
export default class LabelTranslations extends Vue {
  labels: any = null;
  languages: any = {
    admin_app: [],
    frontend_app: []
  }

  mounted() {
    this.getLabelDetail();
  }

  get locale() {
    const key: any = 'label';
    let groups: any = [];

    this.labels.forEach((l: any) => {
      groups.push(l.groups[0].name);
    })

    const locales = this.$store.getters["root/locale"]
        .filter(function (loc: any) {
          return groups.indexOf(loc.applicationName) > -1;
        });

    this.languages[process.env.VUE_APP_FRONT_GROUP_NAME] = this.$store.getters["root/locale"]
        .filter((loc: any) => loc.applicationName === process.env.VUE_APP_FRONT_GROUP_NAME)
        .map((o: any) => o.name);

    this.languages[process.env.VUE_APP_ADMIN_GROUP_NAME] = this.$store.getters["root/locale"]
        .filter((loc: any) => loc.applicationName === process.env.VUE_APP_ADMIN_GROUP_NAME)
        .map((o: any) => o.name);

    return [...new Map(locales.map((item: any) =>
        [item[key], item])).values()];
  }

  get loading() {
    return this.$store.getters["translations/isBusy"];
  }

  addLabel(i: any, loc: any) {
    this.labels[i].translations.push({
      "labelId": this.labels[i].id,
      "locale": loc,
      "value": ""
    })
  }

  getLabelDetail() {
    this.$store.dispatch('translations/getLabelDetail', this.$route.params.id)
        .then((data: any) => {
          this.labels = data;
        })
  }

  split(id: number | string) {
    this.$store.dispatch('translations/splitGroup', id)
        .then(() => {
          this.getLabelDetail();
        })
  }

  saveTranslations() {
    const arrayToSave: any = []
    this.labels.forEach((label: any) => {
      label.translations.forEach(
          (trans: any) => {
            arrayToSave.push(trans);
          }
      )
    })

    this.$store.dispatch('translations/postLabelTranslations', arrayToSave)
        .then(() => {
          this.getLabelDetail();
        })
  }

}
